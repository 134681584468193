/**
 * Check if the user is a phone order user, display a banner and append the user_id to checkout links if so.
 */
document.addEventListener('DOMContentLoaded', () => {
  const URL_PARAMS_KEY = 'user_id';
  const LOCALSTORAGE_KEY = URL_PARAMS_KEY;
  const BANNER_TEXT =
    'You are operating this site as a sales agent on behalf of a customer, and commissions will be awarded.';

  storeUserIdFromUrl();
  const userId = getUserIdFromLocalStorage();

  updatePhoneOrderUserBanner(userId);

  if (userId) {
    appendUserIdToCheckoutPaternityLabLinks(userId);
  }

  function storeUserIdFromUrl() {
    let userId = new URLSearchParams(window.location.search).get(URL_PARAMS_KEY);
    if (userId) {
      localStorage.setItem(LOCALSTORAGE_KEY, userId);
    }
  }

  function getUserIdFromLocalStorage() {
    return localStorage.getItem(LOCALSTORAGE_KEY);
  }

  function updatePhoneOrderUserBanner(userId) {
    let element = document.getElementById('phone-order-user-notification');
    if (!element) {
      throw new Error('Phone order user banner element is not present in DOM.');
    }

    if (userId) {
      element.innerHTML = BANNER_TEXT;
      element.classList.remove('is-hidden');
    } else {
      element.innerHTML = '';
    }
  }

  function appendUserIdToCheckoutPaternityLabLinks(userId) {
    document.querySelectorAll('a[href*="checkout.paternitylab"]').forEach((link) => {
      let url = new URL(link.href);
      url.searchParams.set(URL_PARAMS_KEY, userId);
      link.href = url;
    });
  }
});
