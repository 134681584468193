import { Cookies } from './cookies';

jQuery(function () {
  checkCoupon()
    .then((coupon) => {
      if (coupon.deleted_at) {
        notifyCouponHasExpired(coupon.coupon_name);

        return;
      }

      saveCoupon(coupon);
      notifyCouponApplied(coupon);
    })
    .catch(({ type, couponInfo }) => {
      switch (type) {
        case 'NOT_FOUND':
          removeCoupon();
          notifyCouponNotExists(couponInfo);

          break;

        case 'GET_PARAMETER_NOT_SET':
        default:
          checkCouponAlreadyApplied();
      }
    });
});

/**
 * Send a request to stdcheck to validate the coupon and store it into the cookies
 *
 * @returns {Promise} If it validates, returns the coupon, otherwise it returns the error type and couponInfo
 */
const checkCoupon = () => {
  return new Promise((resolve, reject) => {
    const couponCode = getCouponCode();

    if (!couponCode.length) {
      reject({ type: 'GET_PARAMETER_NOT_SET', couponInfo: undefined });

      return;
    }

    jQuery
      .ajax({
        url: `${process.env.API_URL}/coupon/${couponCode}`,
        contentType: 'application/json',
      })
      .then((data) => {
        const coupon = {
          coupon_code: data.coupon_code,
          coupon_name: data.coupon_name,
          deleted_at: data.deleted_at,
        };

        resolve(coupon);
      })
      .catch(() => {
        reject({ type: 'NOT_FOUND', couponInfo: couponCode });
      });
  });
};

/**
 * Get the coupon code from the GET parameters
 *
 * @returns {string} coupon parameter in URL or empty string
 */
const getCouponCode = () => {
  return new URLSearchParams(window.location.search).get('coupon') || '';
};

/**
 * Check if the coupon is already applied and is stored in cookies
 */
const checkCouponAlreadyApplied = () => {
  Cookies.get('coupon')
    .then((coupon) => {
      notifyCouponApplied(JSON.parse(decodeURIComponent(coupon)));
    })
    .catch(() => {});
};

/**
 * Save the coupon in cookies
 *
 * @param coupon the coupon to be saved
 */
const saveCoupon = (coupon) => {
  Cookies.put('coupon', encodeURIComponent(JSON.stringify(coupon)), {
    domain: `.${window.location.hostname.replace('www.', '')}`,
    path: '/',
  });
};

/**
 * Remove the coupon from cookies
 */
const removeCoupon = () => {
  Cookies.remove('coupon', {
    domain: `.${window.location.hostname.replace('www.', '')}`,
    path: '/',
  });
};

/**
 * Show the applied coupon bar element
 * @param coupon the coupon to be displayed
 */
const notifyCouponApplied = (coupon) => {
  const notificationElement = jQuery('#notification-coupon-applied');

  notificationElement.removeClass('is-hidden');
  notificationElement.find('strong').html(coupon.coupon_name);
};

/**
 * Show the error coupon bar element
 */
const notifyCouponError = () => {
  const notificationElement = jQuery('#notification-coupon-error');

  notificationElement.removeClass('is-hidden');
};

/**
 * Show the error not exists coupon bar element
 */
const notifyCouponNotExists = (couponCode) => {
  const notificationNotExistElement = jQuery('#notification-coupon-error > #not-exist');

  notificationNotExistElement.removeClass('is-hidden');
  notificationNotExistElement.find('strong').html(couponCode);
  notifyCouponError();
};

/**
 * Show the "coupon is expired" error in the bar element
 */
const notifyCouponHasExpired = (couponCode) => {
  const notificationExpiredElement = jQuery('#notification-coupon-error > #no-longer-available');

  notificationExpiredElement.removeClass('is-hidden');
  notificationExpiredElement.find('strong').html(couponCode);
  notifyCouponError();
};
