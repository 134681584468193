export const Cookies = {
  get: (name) =>
    new Promise(function (resolve, reject) {
      if (
        !document.cookie.split('; ').some(function (cookieKeyValue) {
          const cookieKeyValueParts = cookieKeyValue.split('=');
          if (cookieKeyValueParts[0] === name) {
            resolve(cookieKeyValueParts[1]);
            return true;
          }

          return false;
        })
      ) {
        reject();
      }
    }),
  put: (name, value, options) => {
    document.cookie = Cookies.assemble(name, value, Object.assign(Cookies.defaultCookieOptions(), options));
  },
  remove: (name, options) => {
    if (!options) {
      options = {};
    }

    const expires = new Date();
    expires.setTime(0);
    options.expires = expires;
    Cookies.put(name, '', options);
  },
  assemble: (name, value, options) => {
    const cookieParts = [`${name}=${value}`];

    for (let property in options) {
      if (!options.hasOwnProperty(property)) {
        throw `Unexpected property of CookieOptions: ${property}`;
      }
      switch (property) {
        case 'domain':
        case 'path':
        case 'sameSite':
          cookieParts.push(`${property[0].toUpperCase()}${property.slice(1)}=${options[property]}`);
          break;
        case 'secure':
          if (!options[property]) {
            continue;
          }
          cookieParts.push('Secure');
          break;
        case 'expires':
          cookieParts.push(`Expires=${options[property].toUTCString()}`);
          break;
      }
    }

    return cookieParts.join('; ');
  },
  defaultCookieOptions: () => {
    let expires = new Date();
    expires.setDate(expires.getDate() + 2);
    return {
      domain: window.location.hostname,
      path: window.location.pathname,
      sameSite: 'Lax',
      secure: true,
      expires: expires,
    };
  },
};
