import * as Sentry from '@sentry/browser';

const isProd = !window.location.hostname.includes('local');

Sentry.init({
  dsn: 'https://394fe03caaef4236b6ab5dd2d01bc60d@o510915.ingest.sentry.io/5822064',
  environment: isProd ? 'production' : 'local',
  sampleRate: isProd ? 1 : 0,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: isProd ? 0.03 : 0,
});
