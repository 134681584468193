document.addEventListener('DOMContentLoaded', () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'merchantWidgetScript';
  script.src = 'https://www.gstatic.com/shopping/merchant/merchantwidget.js';
  script.onload = () =>
    merchantwidget &&
    typeof merchantwidget.start === 'function' &&
    merchantwidget.start({
      position: 'LEFT_BOTTOM',
      sideMargin: 8,
      bottomMargin: 2,
      mobileSideMargin: 8,
      mobileBottomMargin: 24,
    });
  document.head.appendChild(script);
});
