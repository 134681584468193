document.addEventListener('DOMContentLoaded', () => {
  const navMenuContainers = document.querySelectorAll('.menu-item-has-children');

  navMenuContainers.forEach((container) => {
    container.addEventListener('click', () => {
      const subMenu = container.querySelector('.drop-holder');
      if (subMenu) {
        subMenu.style.display = subMenu.style.display === 'block' ? 'none' : 'block';
      }
    });
  });
});
