import { Cookies } from './cookies';

const localeUs = 'en_US';
const localeCa = 'en_CA';
const cfLocaleCa = 'CA';
const subFolderCa = '/ca';
const cfTraceUrl = 'https://paternitylab.com/cdn-cgi/trace';
const cookieDomain = process.env.COOKIE_DOMAIN;

/**
 * Redirect the current page to preferred language.
 * If there's no preferred language locale stored in LocalStorage, it will check Cloudflare location for proper locale
 * Otherwise it will redirect the current page if the preferred locale is not matching with the current URL.
 */
function redirectToPreferredSiteLanguage() {
  const subFolder = getSubFolder();
  Cookies.get('current_locale').then(
    (selection) => {
      compareAndRedirect(selection, subFolder);
    },
    () => {
      let selection;
      jQuery
        .ajax({
          url: cfTraceUrl,
        })
        .then((data) => {
          const match = data.match(/loc\=(\w{2})/);
          if (match.length && match[1] === cfLocaleCa) {
            selection = localeCa;
          } else {
            selection = localeUs;
          }
          const expires = new Date();
          expires.setDate(expires.getDate() + 365);
          Cookies.put('current_locale', selection, {
            domain: cookieDomain,
            path: '/',
            expires,
            secure: false,
          });
          compareAndRedirect(selection, subFolder);
        });
    }
  );
}

/**
 * Compare the selection of language and force redirect if the current URL is not CA while the preselected locale is CA
 * @param {string} selection the selected locale in LocalStorage
 * @param {string} subFolder the sub-folder of the current URL
 */
function compareAndRedirect(selection, subFolder) {
  if (selection === localeCa && subFolder !== subFolderCa) {
    window.location.href =
      window.location.protocol + '//' + window.location.host + '/ca' + window.location.pathname.replace(subFolder, '');
  }
}

/**
 * Get the sub-folder for the current URL. The possible result could be:
 * Production: '/ca', ''
 * Local: '/ca', '/us'
 */
function getSubFolder() {
  const match = window.location.pathname.match(/^(\/\w{2})\//);
  if (match && match.length === 2) {
    return match[1];
  }

  return '';
}

redirectToPreferredSiteLanguage();
